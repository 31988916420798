import { Component, WritableSignal, effect, signal } from '@angular/core';
import { ContainerComponent } from '../container/container.component';
import { PricingPlanComponent } from '../pricing-plan/pricing-plan.component';
import { AuthService } from '../../services/auth.service';
import { CurrencySelectComponent } from "../currency-select/currency-select.component";
import { CryptoSelectComponent } from "../crypto-select/crypto-select.component";
import { BillingCycleSelectComponent } from "../billing-cycle-select/billing-cycle-select.component";
import { ICountryPrices, IPaidProduct, IProduct, IProductId } from '../../../shared/prices';
import { CurrencyPipe } from '../../pipes/currency.pipe';
import supportedCountries from '../../../shared/countries';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { GetCryptoPriceEstimatesResponse, Price, ProductData } from '../../services/api.services';
import { ProductService } from '../../services/product.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CryptocurrencyService } from '../../services/cryptocurrency.service';

@Component({
  selector: 'cs-pricing',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    ContainerComponent,
    PricingPlanComponent,
    CurrencySelectComponent,
    CurrencyPipe,
    CryptoSelectComponent,
    BillingCycleSelectComponent
  ],
  templateUrl: './pricing.component.html',
  styleUrl: './pricing.component.scss',
})
export class PricingComponent {
  isLoading = true;
  isAuthenticated = this.authService.isAuthenticated$;
  billingCycle: WritableSignal<keyof ICountryPrices> = signal('monthly');
  countryCode = signal('US');
  currencyCode = 'usd';
  cryptoSymbol = signal('BTC');
  showCryptoPrices = false;
  locale = 'en-US';

  currentPrice: { [key: string]: number } = {
    free: 0,
    verified: 0,
    standard: 0,
    premium: 0,
  };

  cryptoPrice: { [key: string]: string } = {
    free: '',
    verified: '',
    standard: '',
    premium: '',
  };

  products: WritableSignal<ProductData | undefined> = signal(undefined);
  productsSorted: (IProduct | IPaidProduct)[] = [];

  constructor(private authService: AuthService, productService: ProductService, private cryptocurrencyService: CryptocurrencyService) {
    effect(() => {
      this.getFiatPrices();
      this.formatCryptoPrices();
    });

    authService.userProfile$.pipe(takeUntilDestroyed()).subscribe({
      next: (profile) => {
        if (profile?.locationCountryCode) {
          this.countryCode.set(profile.locationCountryCode);
        }
      }
    });

    productService.data$.pipe(takeUntilDestroyed()).subscribe({
      next: (products) => {
        if (products) {
          this.products.set(products);
          const { free, verified, standard, premium } = products;
          this.productsSorted = [free!, verified!, standard!, premium!];
        }
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  getFiatPrices() {
    const countryCode = this.countryCode();
    const billingCycle = this.billingCycle();
    this.currencyCode = supportedCountries.find(c => c.countryCode === countryCode)?.currencyCode || 'USD';
    this.locale = supportedCountries.find(c => c.countryCode === countryCode)?.locale || 'en-US';
    this.currentPrice['verified'] = this.getPrice('verified', 'yearly', countryCode);
    this.currentPrice['standard'] = this.getPrice('standard', billingCycle, countryCode);
    this.currentPrice['premium'] = this.getPrice('premium', billingCycle, countryCode);
  }

  getPrice(productId: IProductId, billingCycle: keyof(ICountryPrices), countryCode: string) {
    const products = this.products();
    if (!products) return 0;

    const price = products[productId]?.prices[countryCode.toLowerCase()][billingCycle] as Price | undefined;
    if (!price) return 0;

    return price?.amount?.amount || 0;
  }

  onCountryChange(countryCode: string) {
    if (this.showCryptoPrices) {
      this.onCryptoChange(this.cryptoSymbol(), countryCode);
    }
  }

  onCryptoClicked() {
    this.showCryptoPrices = true;
  }

  cryptoPriceEstimate: GetCryptoPriceEstimatesResponse | null = null;
  onCryptoChange(symbol: string, country?: string) {
    this.cryptoPriceEstimate = null;
    this.cryptoPrice['verified'] = `Estimating ${symbol}...`;
    this.cryptoPrice['standard'] = `Estimating ${symbol}...`;
    this.cryptoPrice['premium'] = `Estimating ${symbol}...`;

    this.cryptocurrencyService.getPriceEstimate(symbol, country || this.countryCode()).subscribe({
      next: (response) => {
        this.cryptoPriceEstimate = response;
        this.formatCryptoPrices();
      }
    });
  }

  formatCryptoPrices() {
    const monthly = this.billingCycle() === 'monthly';
    const verifiedPrice = this.cryptoPriceEstimate?.verified?.yearly;
    const standardPrice = monthly ? this.cryptoPriceEstimate?.standard?.monthly : this.cryptoPriceEstimate?.standard?.yearly;
    const premiumPrice = monthly ? this.cryptoPriceEstimate?.premium?.monthly : this.cryptoPriceEstimate?.premium?.yearly;
    this.cryptoPrice['free'] = `0 ${this.cryptoSymbol()}`;
    this.cryptoPrice['verified'] = `${(verifiedPrice || '?')} ${this.cryptoSymbol()}`;
    this.cryptoPrice['standard'] = `${(standardPrice || '?')} ${this.cryptoSymbol()}`;
    this.cryptoPrice['premium'] = `${(premiumPrice || '?')} ${this.cryptoSymbol()}`;
  }

}
