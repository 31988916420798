<section id="pricing" aria-label="Pricing" class="py-20 sm:py-32">
  <div class="mx-auto max-w-[120rem] px-4 sm:px-6 lg:px-8">
    <div class="md:text-center">
      <h2 class="font-display text-3xl tracking-tight sm:text-4xl">
        <span class="relative whitespace-nowrap"> <span class="relative">Simple pricing,</span> </span> for everyone.
      </h2>
      <p class="mt-4 text-2xl font-light text-pink-100">
        To get the most out of your time in the <span class="text-pink-200">Casuals</span> community, <br class="hidden xl:inline">choose the plan that's right for you.
      </p>

      <div class="md:flex gap-4 justify-center pt-6 text-center">
        <div class="flex gap-4 justify-center">
          <div>
            <cs-currency-select [value]="countryCode" (valueChange)="onCountryChange($event)"></cs-currency-select>
            <div class="mt-1">
              <a class="text-sm text-pink-100 hover:underline" routerLink="/help/regional-pricing">Regional pricing</a>
            </div>
          </div>
          <div class="pt-3">or</div>
          <div>
            <cs-crypto-select [value]="cryptoSymbol" (valueChange)="onCryptoChange($event)" (opened)="onCryptoClicked()"></cs-crypto-select>
            <div class="mt-1">
              <a class="text-sm text-pink-100 hover:underline" routerLink="/help/payment-options">Payment options</a>
            </div>
          </div>
        </div>
        <div id="billing-cycle-option" class="flex justify-center relative w-full md:max-w-sm pt-6 md:pt-0 select-none px-4 sm:px-6 lg:px-8">
          <cs-billing-cycle-select [value]="billingCycle"></cs-billing-cycle-select>
        </div>
      </div>
    </div>
    <div
      class="mt-10 grid max-w-3xl grid-cols-1 gap-y-10 sm:mx-auto md:max-w-none md:grid-cols-2 md:gap-x-4 xl:grid-cols-4 xl:mx-0 xl:gap-x-8"
    >
        <cs-pricing-plan
          *ngFor="let product of productsSorted"
          [name]="product.name!"
          [price]="currentPrice[product.id!] | currency :currencyCode :locale"
          [country]="countryCode()"
          [cryptoPrice]="showCryptoPrices ? cryptoPrice[product.id!] : ''"
          [cryptoSymbol]="showCryptoPrices ? cryptoSymbol() : ''"
          [billingCycle]="product.id === 'verified' ? 'yearly' : billingCycle()"
          [description]="product.description!"
          [productId]="product.id!"
          [features]="product.features!"
          [featured]="product.id === 'standard'"
          [popular]="product.id === 'standard'"
          [topTier]="product.id === 'premium'"
          [free]="product.id === 'free'"
          [ngClass]="{
            'order-1 xl:order-3': product.id === 'standard',
            'order-2 xl:order-4': product.id === 'premium',
            'order-3 xl:order-2': product.id === 'verified',
            'order-4 xl:order-1': product.id === 'free'
          }"
        />
    </div>
  </div>
</section>
